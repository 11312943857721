import React from 'react';
import aartiSahu from '../../images/blogs/aartisahu.jpeg';

const BlogWebDevelopment = () => {
	return (
		<div className='relative isolate overflow-hidden bg-white px-10 py-24 sm:py-34 lg:overflow-visible lg:px-12'>
			<div className='lg:col-span-2 lg:gap-x-8 lg:px-8'>
				<div className='relative mt-8 flex items-center gap-x-4'>
					<img
						alt=''
						src={aartiSahu}
						className='h-10 w-10 rounded-full bg-gray-50'
					/>
					<div className='text-sm leading-6'>
						<p className='font-semibold text-gray-900'>Aarti Sahu</p>
						<p className='text-gray-600'>Co-Founder & COO</p>
					</div>
				</div>
				<div className='flex items-center gap-x-4 text-xs'>
					<time dateTime='2024-04-27' className='text-gray-500'>
						27 Apr 2024
					</time>
					<p className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100'>
						Web development
					</p>
				</div>
			</div>
			<div className='lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:gap-x-8 lg:px-8'>
				<h3 className='mt-3 text-lg font-semibold leading-6 text-blue-900 group-hover:text-blue-800'>
					Elevate Your Online Presence with Expert Web Development
				</h3>
				<p className='mt-5 text-sm leading-6 text-gray-600'>
					In today's digital era, a robust online presence is crucial for
					business success. Your website is often the first interaction
					potential customers have with your brand, making it a critical
					component of your marketing strategy. At DelvonTech, we specialize in
					creating dynamic, user-friendly websites that not only look great but
					also drive results. In this blog, we'll delve into the essential
					aspects of web development and how our services can help elevate your
					online presence.
				</p>
				<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
					1. Custom Website Design
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					A well-designed website is the cornerstone of an effective online
					presence. Our custom website design services ensure that your site
					reflects your brand's unique identity and meets your specific business
					needs. From eye-catching graphics to intuitive navigation, we create
					websites that are both aesthetically pleasing and functional.
				</p>
				<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
					2. Responsive Design
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					With the increasing use of mobile devices, having a responsive website
					is no longer optional. Our web development team ensures that your site
					looks and performs flawlessly on all devices, from desktops to
					smartphones. By providing a seamless user experience across all
					platforms, we help you reach a wider audience and keep visitors
					engaged.
				</p>
				<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
					3. E-commerce Solutions
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					For businesses looking to sell products or services online, a powerful
					e-commerce platform is essential. We develop secure, scalable
					e-commerce websites that offer a smooth shopping experience. Our
					solutions include payment gateway integration, inventory management,
					and customer relationship management tools to help you run your online
					store efficiently.
				</p>
				<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
					4. Content Management Systems (CMS)
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					A user-friendly CMS allows you to easily update and manage your
					website content without technical expertise. We specialize in
					developing CMS-powered websites using platforms like WordPress,
					Joomla, and Drupal. Whether you need to add new pages, upload blog
					posts, or update images, our CMS solutions make it simple and
					hassle-free.
				</p>
				<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
					5. SEO-Friendly Development
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					A beautiful website is of little use if it can't be found by search
					engines. Our web development approach incorporates best practices for
					search engine optimization (SEO) to ensure your site ranks well on
					search engine results pages. From clean code and fast loading times to
					keyword optimization and mobile-friendliness, we build websites that
					attract and retain organic traffic.
				</p>
				<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
					6. Web Application Development
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					In addition to standard websites, we also develop custom web
					applications tailored to your business processes. Whether you need a
					customer portal, an internal tool for your team, or a complex
					web-based system, our developers have the expertise to create scalable
					and secure web applications that meet your specific requirements.
				</p>
				<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
					7. Maintenance and Support
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					A successful website requires ongoing maintenance and support to keep
					it running smoothly and securely. Our maintenance services include
					regular updates, security checks, performance optimization, and
					troubleshooting. We ensure that your website remains up-to-date and
					performs at its best, allowing you to focus on your core business
					activities.
				</p>
				<h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-700'>
					Conclusion
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					Investing in professional web development is essential for
					establishing a strong online presence and achieving business success.
					At DelvonTech, we are committed to delivering high-quality web
					development services that drive results. Contact us today to learn how
					our tailored web solutions can help you achieve your online goals and
					stand out in the digital landscape.
				</p>
			</div>
		</div>
	);
};

export default BlogWebDevelopment;

import React from "react";

const stats = [
	{ id: 1, name: 'Projects completed', value: '15+' },
	{ id: 2, name: 'Happy Clients', value: '11+' },
	{ id: 3, name: 'Awards Won', value: '5' },
];

const OurRatio = () => {
	return (
		<div className='cta py-24 sm:py-32'>
			<div className='mx-auto max-w-7xl px-6 lg:px-8'>
				<dl className='grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3'>
					{stats.map((stat) => (
						<div
							key={stat.id}
							className='mx-auto flex max-w-xs flex-col gap-y-4'
						>
							<dt className='text-base leading-7 text-white'>{stat.name}</dt>
							<dd className='order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl'>
								{stat.value}
							</dd>
						</div>
					))}
				</dl>
			</div>
		</div>
	);
}

export default OurRatio;

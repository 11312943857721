import React from 'react';
import peterlazar from '../../images/clients/peterlazar.jpg';
import andrewoons from '../../images/clients/andrewoons.jpg';
import davidsutton from '../../images/clients/davidsutton.jpg';
import sumitgupta from '../../images/clients/sumitgupta.jpg';
import nivodalogo from '../../images/clients/nivodalogo.png';
import scanlilyLogo from '../../images/clients/scanlilylogo.png';
import enukeLogo from '../../images/clients/enukelogo.jpg';

const Testimonials = () => {
	return (
		<>
			<div className='my-4 py-4' id='Testimonials'>
				<h2 className='my-2 text-center text-3xl text-blue-900 uppercase font-bold'>
					Testimonials
				</h2>
				<div className='flex justify-center'>
					<div className='w-24 border-b-4 border-blue-900'></div>
				</div>
				<h2 className='mt-4 mx-12 mb-8 text-center text-xl lg:text-2xl font-semibold text-blue-900'>
					We always listen to our clients.
				</h2>

				<div className='px-4' data-aos='fade-down' data-aos-delay='600'>
					<div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-5'>
						<div className='bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max group'>
							<div className='mx-auto max-w-2xl lg:max-w-4xl'>
								<img alt='' src={scanlilyLogo} className='mx-auto h-12' />
								<figure className='mt-10'>
									<blockquote className='text-center font-semibold leading-6 text-gray-700 text-sm font-medium'>
										<p>
											“Thanks to DelvonTech's dedicated efforts, we now have an
											outstanding website. The team's hands-on approach and
											excellent communication fostered a successful
											collaboration. They are talented, dedicated, and patient,
											always focused on overcoming the toughest challenges, just
											like the ones we faced. When it comes to contracting and
											financial's, they are flexible and committed to making
											things work for their clients.”
										</p>
									</blockquote>
									<figcaption className='mt-10'>
										<img
											alt=''
											src={peterlazar}
											className='mx-auto h-10 w-10 rounded-full'
										/>
										<div className='mt-4 flex items-center justify-center space-x-3 text-base'>
											<div className='font-semibold text-gray-900'>
												Peter Lazar
											</div>
											<svg
												width={3}
												height={3}
												viewBox='0 0 2 2'
												aria-hidden='true'
												className='fill-gray-900'
											>
												<circle r={1} cx={1} cy={1} />
											</svg>
											<div className='text-gray-600'>CEO of Scanlily</div>
										</div>
									</figcaption>
								</figure>
							</div>
						</div>

						<div className='bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max group'>
							<div className='mx-auto max-w-2xl lg:max-w-4xl'>
								<img alt='' src={nivodalogo} className='mx-auto h-12' />
								<figure className='mt-10'>
									<blockquote className='text-center font-semibold leading-6 text-gray-700 text-sm font-medium'>
										<p>
											“I am incredibly grateful toDelvonTechfor their
											unwavering dedication in creating our exceptional website.
											Their proactive approach and clear communication made our
											collaboration a true success. The team's remarkable skill,
											commitment, and patience were evident as they tackled
											every challenge we presented. Their flexibility with
											contracts and financial arrangements shows their genuine
											commitment to their clients' success. Thank you,
											DelvonTech, for your outstanding work and support.”
										</p>
									</blockquote>
									<figcaption className='mt-10'>
										<img
											alt=''
											src={davidsutton}
											className='mx-auto h-10 w-10 rounded-full'
										/>
										<div className='mt-4 flex items-center justify-center space-x-3 text-base'>
											<div className='font-semibold text-gray-900'>
												David Sutton
											</div>
											<svg
												width={3}
												height={3}
												viewBox='0 0 2 2'
												aria-hidden='true'
												className='fill-gray-900'
											>
												<circle r={1} cx={1} cy={1} />
											</svg>
											<div className='text-gray-600'>CEO of Nivoda</div>
										</div>
									</figcaption>
								</figure>
							</div>
						</div>

						<div className='bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max group'>
							<div className='mx-auto max-w-2xl lg:max-w-4xl'>
								<img alt='' src={nivodalogo} className='mx-auto h-12' />
								<figure className='mt-10'>
									<blockquote className='text-center font-semibold leading-6 text-gray-700 text-sm font-medium'>
										<p>
											“I've had the pleasure of working with the team at
											DelvonTech, and their performance is nothing short of
											exceptional. Their speed and efficiency are impressive,
											and their deep expertise consistently delivers outstanding
											results. The team's talent and dedication shine through in
											every project, making them a truly remarkable partner.”
										</p>
									</blockquote>
									<figcaption className='mt-10'>
										<img
											alt=''
											src={andrewoons}
											className='mx-auto h-10 w-10 rounded-full'
										/>
										<div className='mt-4 flex items-center justify-center space-x-3 text-base'>
											<div className='font-semibold text-gray-900'>
												Andre Woons
											</div>
											<svg
												width={3}
												height={3}
												viewBox='0 0 2 2'
												aria-hidden='true'
												className='fill-gray-900'
											>
												<circle r={1} cx={1} cy={1} />
											</svg>
											<div className='text-gray-600'>CTO of Nivoda</div>
										</div>
									</figcaption>
								</figure>
							</div>
						</div>

						<div className='bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max group'>
							<div className='mx-auto max-w-2xl lg:max-w-4xl'>
								<img alt='' src={enukeLogo} className='mx-auto h-12' />
								<figure className='mt-10'>
									<blockquote className='text-center font-semibold leading-6 text-gray-700 text-sm font-medium'>
										<p>
											“I am deeply thankful for the opportunity to work with the
											team at DelvonTech. Their exceptional capabilities, quick
											turnaround times, and profound knowledge have consistently
											impressed us. The team's talent, dedication, and
											unwavering commitment to excellence make them an
											invaluable partner. We highly appreciate DelvonTech's
											outstanding service and expertise.”
										</p>
									</blockquote>
									<figcaption className='mt-10'>
										<img
											alt=''
											src={sumitgupta}
											className='mx-auto h-10 w-10 rounded-full'
										/>
										<div className='mt-4 flex items-center justify-center space-x-3 text-base'>
											<div className='font-semibold text-gray-900'>
												Sumit Gupta
											</div>
											<svg
												width={3}
												height={3}
												viewBox='0 0 2 2'
												aria-hidden='true'
												className='fill-gray-900'
											>
												<circle r={1} cx={1} cy={1} />
											</svg>
											<div className='text-gray-600'>CEO of Enuke</div>
										</div>
									</figcaption>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Testimonials;

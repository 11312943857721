import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";

const PageNotFound = () => {
	useEffect(() => {
		document.title = '404 - Page Not Found';
	}, []);
	return (
		<>
      <Helmet>
        <title>404 - Page Not Found</title>
      </Helmet>
			<div className='grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
				<div className='text-center'>
					<h1 className='text-base font-semibold text-5xl font-bold text-blue-900'>
						404
					</h1>
					<h2 className='mt-4 text-3xl font-bold tracking-tight text-blue-900 sm:text-5xl'>
						Page not found
					</h2>
					<p className='mt-6 text-base leading-7 text-gray-700'>
						Sorry, we couldn't find the page you're looking for.
					</p>
					<div className='mt-10 flex items-center justify-center gap-x-6'>
						<a
							href='/'
							className='rounded-md bg-blue-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
						>
							Go back home
						</a>
						<a href='/contact' className='text-md font-bold text-blue-900'>
							Contact support <span aria-hidden='true'>&rarr;</span>
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default PageNotFound;

const PrivacyPolicy = () => {
	return (
		<div className='bg-white py-24 sm:py-32'>
			<div className='mx-auto max-w-7xl px-6 lg:px-8'>
				<h1 className='md:text-5xl text-3xl font-bold tracking-tight text-blue-900'>
					Privacy Policy
				</h1>
				<div className='relative isolate overflow-hidden bg-white py-10 sm:py-15 lg:overflow-visible'>
					<div className='lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto'>
						<p className='mt-5 text-sm leading-6 text-gray-600'>
							DelvonTech Infosystem Pvt Ltd operates the www.delvontech
							website, which provides the services of IT solutions, including
							web development, app development, full stack development, UI/UX
							design, product management, and hosting services.
						</p>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							This page is used to inform website visitors regarding our
							policies with the collection, use, and disclosure of Personal
							Information if anyone decides to use our Service.
						</p>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							If you choose to use our Service, then you agree to the collection
							and use of information in relation to this policy. The Personal
							Information that we collect is used for providing and improving
							the Service. We will not use or share your information with anyone
							except as described in this Privacy Policy.
						</p>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							The terms used in this Privacy Policy have the same meanings as in
							our Terms and Conditions, which is accessible at
							www.delvontech/terms, unless otherwise defined in this Privacy
							Policy.
						</p>
						<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
							Information Collection and Use
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							For a better experience while using our Service, we may require
							you to provide us with certain personally identifiable
							information, including but not limited to your name, phone number,
							and postal address. The information that we collect will be used
							to contact or identify you.
						</p>
						<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
							Log Data
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							We want to inform you that whenever you visit our Service, we
							collect information that your browser sends to us that is called
							Log Data. This Log Data may include information such as your
							computer's Internet Protocol (“IP”) address, browser version,
							pages of our Service that you visit, the time and date of your
							visit, the time spent on those pages, and other statistics.
						</p>
						<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
							Cookies
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							Cookies are files with a small amount of data that are commonly
							used as an anonymous unique identifier. These are sent to your
							browser from the website that you visit and are stored on your
							computer's hard drive.
						</p>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							Our website uses these “cookies” to collect information and to
							improve our Service. You have the option to either accept or
							refuse these cookies, and know when a cookie is being sent to your
							computer. If you choose to refuse our cookies, you may not be able
							to use some portions of our Service.
						</p>
						<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
							Service Providers
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							We may employ third-party companies and individuals for the
							following reasons:
							<li>To facilitate our Service;</li>
							<li>To provide the Service on our behalf;</li>
							<li>To perform Service-related services; or</li>
							<li>To assist us in analyzing how our Service is used.</li>
						</p>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							<p className='mt-2 text-sm leading-6 text-gray-600'>
								Our website uses these “cookies” to collect information and to
								improve our Service. You have the option to either accept or
								refuse these cookies, and know when a cookie is being sent to
								your computer. If you choose to refuse our cookies, you may not
								be able to use some portions of our Service.
							</p>
						</p>
						<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
							Security
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							We value your trust in providing us with your Personal
							Information, thus we strive to use commercially acceptable means
							of protecting it. But remember that no method of transmission over
							the internet, or method of electronic storage is 100% secure and
							reliable, and we cannot guarantee its absolute security.
						</p>
						<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
							Links to Other Sites
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							Our Service may contain links to other sites. If you click on a
							third-party link, you will be directed to that site. Note that
							these external sites are not operated by us. Therefore, we
							strongly advise you to review the Privacy Policy of these
							websites. We have no control over, and assume no responsibility
							for the content, privacy policies, or practices of any third-party
							sites or services.
						</p>
						<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
							Children's Privacy
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							Our Services do not address anyone under the age of 18. We do not
							knowingly collect personally identifiable information from
							children under 18. In the case we discover that a child under 18
							has provided us with personal information, we immediately delete
							this from our servers. If you are a parent or guardian and you are
							aware that your child has provided us with personal information,
							please contact us so that we will be able to take necessary
							actions.
						</p>
						<h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-700'>
							Changes to This Privacy Policy
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							We may update our Privacy Policy from time to time. Thus, we
							advise you to review this page periodically for any changes. We
							will notify you of any changes by posting the new Privacy Policy
							on this page. These changes are effective immediately after they
							are posted on this page.
						</p>
						<h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-700'>
							Contact Us
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							If you have any questions or suggestions about our Privacy Policy,
							do not hesitate to contact us at info@delvontech.
						</p>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							This Privacy Policy page was created at
							www.delvontech/privacy-policy.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicy;

const TermsAndConditions = () => {
	return (
		<div className='bg-white py-24 sm:py-32'>
			<div className='mx-auto max-w-7xl px-6 lg:px-8'>
				<h1 className='md:text-5xl text-3xl font-bold tracking-tight text-blue-900'>
					Terms and Conditions
				</h1>
				<div className='relative isolate overflow-hidden bg-white py-10 sm:py-15 lg:overflow-visible'>
					<div className='lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto'>
						<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
							1. Introduction
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							These terms and conditions shall govern your use of our website.
							1.1. By using our website, you accept these terms and conditions
							in full; accordingly, if you disagree with these terms and
							conditions or any part of these terms and conditions, you must not
							use our website. 1.2. If you register with our website, submit any
							material to our website, or use any of our website services, we
							will ask you to expressly agree to these terms and conditions.
							1.3. You must be at least 18 years of age to use our website; by
							using our website or agreeing to these terms and conditions, you
							warrant and represent to us that you are at least 18 years of age.
							1.4. Our website uses cookies; by using our website or agreeing to
							these terms and conditions, you consent to our use of cookies in
							accordance with the terms of our privacy and cookies policy.
						</p>
						<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
							2. Licence to Use Website
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							2.1. You may: (a) view pages from our website in a web browser;
							(b) download pages from our website for caching in a web browser;
							(c) print pages from our website; (d) stream audio and video files
							from our website; and (e) use our website services by means of a
							web browser, subject to the other provisions of these terms and
							conditions. 2.2. Except as expressly permitted by these terms and
							conditions, you must not download any material from our website or
							save any such material to your computer. 2.3. You may only use our
							website for your own personal and business purposes, and you must
							not use our website for any other purposes. 2.4. Except as
							expressly permitted by these terms and conditions, you must not
							edit or otherwise modify any material on our website. 2.5. Unless
							you own or control the relevant rights in the material, you must
							not: (a) republish material from our website (including
							republication on another website); (b) sell, rent, or sub-license
							material from our website; (c) show any material from our website
							in public; (d) exploit material from our website for a commercial
							purpose; or (e) redistribute material from our website. 2.6. We
							reserve the right to restrict access to areas of our website, or
							indeed our whole website, at our discretion; you must not
							circumvent or bypass, or attempt to circumvent or bypass, any
							access restriction measures on our website.
						</p>
						<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
							3. Acceptable Use
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							3.1. You must not: (a) use our website in any way or take any
							action that causes, or may cause, damage to the website or
							impairment of the performance, availability, or accessibility of
							the website; (b) use our website in any way that is unlawful,
							illegal, fraudulent, or harmful, or in connection with any
							unlawful, illegal, fraudulent, or harmful purpose or activity; (c)
							use our website to copy, store, host, transmit, send, use,
							publish, or distribute any material which consists of (or is
							linked to) any spyware, computer virus, Trojan horse, worm,
							keystroke logger, rootkit, or other malicious computer software;
							(d) conduct any systematic or automated data collection activities
							(including without limitation scraping, data mining, data
							extraction, and data harvesting) on or in relation to our website
							without our express written consent; (e) access or otherwise
							interact with our website using any robot, spider, or other
							automated means, except for the purpose of search engine indexing;
							(f) violate the directives set out in the robots.txt file for our
							website; or (g) use data collected from our website for any direct
							marketing activity (including without limitation email marketing,
							SMS marketing, telemarketing, and direct mailing). 3.2. You must
							not use data collected from our website to contact individuals,
							companies, or other persons or entities. 3.3. You must ensure that
							all the information you supply to us through our website, or in
							relation to our website, is true, accurate, current, complete, and
							non-misleading.
						</p>
						<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
							4. Registration and Accounts
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							4.1. You may register for an account with our website by
							completing and submitting the account registration form on our
							website, and clicking on the verification link in the email that
							the website will send to you. 4.2. You must not allow any other
							person to use your account to access the website. 4.3. You must
							notify us in writing immediately if you become aware of any
							unauthorized use of your account. 4.4. You must not use any other
							person's account to access the website, unless you have that
							person's express permission to do so.
						</p>
						<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
							5. User Login Details
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							5.1. If you register for an account with our website, we will
							provide you with, or you will be asked to choose, a user ID and
							password. 5.2. Your user ID must not be liable to mislead; you
							must not use your account or user ID for or in connection with the
							impersonation of any person. 5.3. You must keep your password
							confidential. 5.4. You must notify us in writing immediately if
							you become aware of any disclosure of your password. 5.5. You are
							responsible for any activity on our website arising out of any
							failure to keep your password confidential, and may be held liable
							for any losses arising out of such a failure.
						</p>
						<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
							6. Cancellation and Suspension of Account
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							6.1. We may: (a) suspend your account; (b) cancel your account;
							and/or (c) edit your account details, at any time in our sole
							discretion without notice or explanation. 6.2. You may cancel your
							account on our website using your account control panel on the
							website.
						</p>
						<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
							7. Your Content: Licence
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							7.1. In these terms and conditions, "your content" means all works
							and materials (including without limitation text, graphics,
							images, audio material, video material, audio-visual material,
							scripts, software, and files) that you submit to us or our website
							for storage or publication on, processing by, or transmission via,
							our website. 7.2. You grant to us a worldwide, irrevocable,
							non-exclusive, royalty-free licence to use, reproduce, store,
							adapt, publish, translate, and distribute your content in any
							existing or future media. 7.3. You hereby waive all your moral
							rights in your content to the maximum extent permitted by
							applicable law; and you warrant and represent that all other moral
							rights in your content have been waived to the maximum extent
							permitted by applicable law. 7.4. You may edit your content to the
							extent permitted using the editing functionality made available on
							our website. 7.5. Without prejudice to our other rights under
							these terms and conditions, if you breach any provision of these
							terms and conditions in any way, or if we reasonably suspect that
							you have breached these terms and conditions in any way, we may
							delete, unpublish, or edit any or all of your content.
						</p>
						<h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-700'>
							8. Your Content: Rules
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							8.1. You warrant and represent that your content will comply with
							these terms and conditions. 8.2. Your content must not be illegal
							or unlawful, must not infringe any person's legal rights, and must
							not be capable of giving rise to legal action against any person
							(in each case in any jurisdiction and under any applicable law).
							8.3. Your content, and the use of your content by us in accordance
							with these terms and conditions, must not: (a) be libellous or
							maliciously false; (b) be obscene or indecent; (c) infringe any
							copyright, moral right, database right, trademark right, design
							right, right in passing off, or other intellectual property right;
							(d) infringe any right of confidence, right of privacy, or right
							under data protection legislation; (e) constitute negligent advice
							or contain any negligent statement; (f) constitute an incitement
							to commit a crime, instructions for the commission of a crime, or
							the promotion of criminal activity; (g) be in contempt of any
							court, or in breach of any court order; (h) be in breach of racial
							or religious hatred or discrimination legislation; (i) be
							blasphemous; (j) be in breach of official secrets legislation; (k)
							be in breach of any contractual obligation owed to any person; (l)
							depict violence in an explicit, graphic, or gratuitous manner; (m)
							be pornographic, lewd, suggestive, or sexually explicit; (n) be
							untrue, false, inaccurate, or misleading; (o) consist of or
							contain any instructions, advice, or other information which may
							be acted upon and could, if acted upon, cause illness, injury, or
							death, or any other loss or damage; (p) constitute spam; (q) be
							offensive, deceptive, fraudulent, threatening, abusive, harassing,
							anti-social, menacing, hateful, discriminatory, or inflammatory;
							or (r) cause annoyance, inconvenience, or needless anxiety to any
							person.
						</p>
						<h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-700'>
							9. Limited Warranties
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							9.1. We do not warrant or represent: (a) the completeness or
							accuracy of the information published on our website; (b) that the
							material on the website is up to date; or (c) that the website or
							any service on the website will remain available. 9.2. We reserve
							the right to discontinue or alter any or all of our website
							services, and to stop publishing our website, at any time in our
							sole discretion without notice or explanation; and save to the
							extent expressly provided otherwise in these terms and conditions,
							you will not be entitled to any compensation or other payment upon
							the discontinuance or alteration of any website services, or if we
							stop publishing the website. 9.3. To the maximum extent permitted
							by applicable law and subject to Section 10.1, we exclude all
							representations and warranties relating to the subject matter of
							these terms and conditions, our website, and the use of our
							website.
						</p>
						<h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-700'>
							10. Limitations and Exclusions of Liability
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							10.1. Nothing in these terms and conditions will: (a) limit or
							exclude any liability for death or personal injury resulting from
							negligence; (b) limit or exclude any liability for fraud or
							fraudulent misrepresentation; (c) limit any liabilities in any way
							that is not permitted under applicable law; or (d) exclude any
							liabilities that may not be excluded under applicable law. 10.2.
							The limitations and exclusions of liability set out in this
							Section 10 and elsewhere in these terms and conditions: (a) are
							subject to Section 10.1; and (b) govern all liabilities arising
							under these terms and conditions or relating to the subject matter
							of these terms and conditions, including liabilities arising in
							contract, in tort (including negligence), and for breach of
							statutory duty. 10.3. To the extent that our website and the
							information and services on our website are provided free of
							charge, we will not be liable for any loss or damage of any
							nature. 10.4. We will not be liable to you in respect of any
							losses arising out of any event or events beyond our reasonable
							control. 10.5. We will not be liable to you in respect of any
							business losses, including (without limitation) loss of or damage
							to profits, income, revenue, use, production, anticipated savings,
							business, contracts, commercial opportunities, or goodwill. 10.6.
							We will not be liable to you in respect of any loss or corruption
							of any data, database, or software. 10.7. We will not be liable to
							you in respect of any special, indirect, or consequential loss or
							damage. 10.8. You accept that we have an interest in limiting the
							personal liability of our officers and employees and, having
							regard to that interest, you acknowledge that we are a limited
							liability entity; you agree that you will not bring any claim
							personally against our officers or employees in respect of any
							losses you suffer in connection with the website or these terms
							and conditions (this will not, of course, limit or exclude the
							liability of the limited liability entity itself for the acts and
							omissions of our officers and employees).
						</p>
						<h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-700'>
							11. Breaches of these Terms and Conditions
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							11.1. Without prejudice to our other rights under these terms and
							conditions, if you breach these terms and conditions in any way,
							or if we reasonably suspect that you have breached these terms and
							conditions in any way, we may: (a) send you one or more formal
							warnings; (b) temporarily suspend your access to our website; (c)
							permanently prohibit you from accessing our website; (d) block
							computers using your IP address from accessing our website; (e)
							contact any or all of your internet service providers and request
							that they block your access to our website; (f) commence legal
							action against you, whether for breach of contract or otherwise;
							and/or (g) suspend or delete your account on our website. 11.2.
							Where we suspend or prohibit or block your access to our website
							or a part of our website, you must not take any action to
							circumvent such suspension or prohibition or blocking (including
							without limitation creating and/or using a different account).
						</p>
						<h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-700'>
							12. Variation
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							12.1. We may revise these terms and conditions from time to time.
							12.2. The revised terms and conditions shall apply to the use of
							our website from the date of publication of the revised terms and
							conditions on the website, and you hereby waive any right you may
							otherwise have to be notified of, or to consent to, revisions of
							these terms and conditions. 12.3. If you have given your express
							agreement to these terms and conditions, we will ask for your
							express agreement to any revision of these terms and conditions;
							and if you do not give your express agreement to the revised terms
							and conditions within such period as we may specify, we will
							disable or delete your account on the website, and you must stop
							using the website.
						</p>
						<h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-700'>
							13. Assignment
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							13.1. You hereby agree that we may assign, transfer, sub-contract,
							or otherwise deal with our rights and/or obligations under these
							terms and conditions. 13.2. You may not without our prior written
							consent assign, transfer, sub-contract, or otherwise deal with any
							of your rights and/or obligations under these terms and
							conditions.
						</p>
						<h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-700'>
							14. Severability
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							14.1. If a provision of these terms and conditions is determined
							by any court or other competent authority to be unlawful and/or
							unenforceable, the other provisions will continue in effect. 14.2.
							If any unlawful and/or unenforceable provision of these terms and
							conditions would be lawful or enforceable if part of it were
							deleted, that part will be deemed to be deleted, and the rest of
							the provision will continue in effect.
						</p>
						<h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-700'>
							15. Third-Party Rights
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							15.1. A contract under these terms and conditions is for our
							benefit and your benefit, and is not intended to benefit or be
							enforceable by any third party. 15.2. The exercise of the parties'
							rights under a contract under these terms and conditions is not
							subject to the consent of any third party.
						</p>
						<h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-700'>
							16. Entire Agreement
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							16.1. Subject to Section 10.1, these terms and conditions,
							together with our privacy and cookies policy, shall constitute the
							entire agreement between you and us in relation to your use of our
							website and shall supersede all previous agreements between you
							and us in relation to your use of our website.
						</p>
						<h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-700'>
							17. Law and Jurisdiction
						</h2>
						<p className='mt-2 text-sm leading-6 text-gray-600'>
							17.1. These terms and conditions shall be governed by and
							construed in accordance with the laws of India. 17.2. Any disputes
							relating to these terms and conditions shall be subject to the
							exclusive jurisdiction of the courts of India.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TermsAndConditions;

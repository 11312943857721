import React from "react";
import { HashLink } from "react-router-hash-link";

const NavLinks = ({closeMenu}) => {
  return (
    <>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        to="/"
        onClick={closeMenu}
      >
        Home
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        to="/#services"
        onClick={closeMenu}
      >
        Services
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        to="/#portfolio"
        onClick={closeMenu}
      >
        Portfolio
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        to="/blog"
        onClick={closeMenu}
      >
        Blog
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        to="/about"
        onClick={closeMenu}
      >
        About
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        to="/privacy-policy"
        onClick={closeMenu}
      >
        Privacy Policy
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        to="/terms-and-conditions"
        onClick={closeMenu}
      >
        Terms & Conditions
      </HashLink>
      <HashLink
        className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
        smooth
        to="/contact"
        onClick={closeMenu}
      >
        Contact Us
      </HashLink>
    </>
  );
};

export default NavLinks;

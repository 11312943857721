import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import nivodalogo from '../../images/clients/nivodalogo.png';
import scanlilyLogo from '../../images/clients/scanlilylogo.png';
import enukeLogo from '../../images/clients/enukelogo.jpg';
import bestpeersLogo from '../../images/clients/bestpeerslogo.png';
import cureasyLogo from '../../images/clients/cureasylogo.png';

const clientImage = {
	height: '5rem',
	width: 'auto',
	margin: 'auto',
};

const Clients = () => {
	const settings = {
		infinite: true,
		speed: 600,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
					infinite: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<div className='mt-8 bg-gray-100'>
			<section data-aos='fade-up'>
				<div className='my-4 py-4'>
					<h2 className='my-2 text-center text-3xl text-blue-900 uppercase font-bold'>
						Our Clients
					</h2>
					<div className='flex justify-center'>
						<div className='w-24 border-b-4 border-blue-900'></div>
					</div>
					<h2 className='mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900'>
						Some of our clients.
					</h2>
				</div>

				<div className='p-16' data-aos='fade-in' data-aos-delay='600'>
					<Slider {...settings}>
						<div className='overflow-hidden p-3 flex justify-center transition-all ease-in-out'>
							<img style={clientImage} src={nivodalogo} alt='client' />
						</div>
						<div className='overflow-hidden p-3 flex justify-center transition-all ease-in-out'>
							<img style={clientImage} src={scanlilyLogo} alt='client' />
						</div>
						<div className='overflow-hidden p-3 flex justify-center transition-all ease-in-out'>
							<img style={clientImage} src={enukeLogo} alt='client' />
						</div>
						<div className='overflow-hidden p-3 flex justify-center transition-all ease-in-out'>
							<img style={clientImage} src={bestpeersLogo} alt='client' />
						</div>
						<div className='overflow-hidden p-3 flex justify-center transition-all ease-in-out'>
							<img style={clientImage} src={cureasyLogo} alt='client' />
						</div>
					</Slider>
				</div>
			</section>
		</div>
	);
};

export default Clients;

import { Link } from 'react-router-dom';
import bhupendraSahu from '../images/blogs/bhupendrasahu.jpeg';
import aartiSahu from '../images/blogs/aartisahu.jpeg';
import naveenNamdev from '../images/blogs/naveennamdev.jpeg';

const posts = [
	{
		id: 1,
		title: 'Transforming Businesses with Comprehensive IT Services',
		href: '/blog/it-services',
		description:
			"In today's rapidly evolving digital landscape, businesses must stay ahead of technological advancements to remain competitive. IT services play a pivotal role in driving innovation, enhancing efficiency, and ensuring security. At DelvonTech, we provide a wide range of IT services tailored to meet the unique needs of each client. In this blog, we'll explore the key IT services that can transform your business and set you on the path to success.",
		date: '27 Apr 2024',
		datetime: '2024-04-27',
		category: { title: 'IT Services', href: '/services/it-consultation' },
		author: {
			name: 'Bhupendra Sahu',
			role: 'Founder & CEO',
			href: '/about/bhupendra-sahu',
			image: bhupendraSahu,
		},
	},
	{
		id: 2,
		title: 'Elevate Your Online Presence with Expert Web Development',
		href: '/blog/web-development',
		description:
			"In today's digital era, a robust online presence is crucial for business success. Your website is often the first interaction potential customers have with your brand, making it a critical component of your marketing strategy. At DelvonTech, we specialize in creating dynamic, user-friendly websites that not only look great but also drive results. In this blog, we'll delve into the essential aspects of web development and how our services can help elevate your online presence.",
		date: '27 Apr 2024',
		datetime: '2024-04-27',
		category: {
			title: 'Web development',
			href: 'services/fullstack-development',
		},
		author: {
			name: 'Aarti Sahu',
			role: 'Co-Founder & COO',
			href: '/about/aarti-sahu',
			image: aartiSahu,
		},
	},
	{
		id: 3,
		title:
			'Revolutionize Your Business with Cutting-Edge Mobile App Development',
		href: '/blog/mobile-app-development',
		description:
			"In today's mobile-centric world, having a robust mobile app is crucial for staying competitive and engaging customers effectively. Mobile apps offer a personalized user experience, enhance customer loyalty, and drive business growth. At DelvonTech, we specialize in creating innovative, user-friendly mobile apps tailored to meet your unique business needs. In this blog, we'll explore the key aspects of mobile app development and how our services can help revolutionize your business.",
		date: '27 Apr 2024',
		datetime: '2024-04-27',
		category: {
			title: 'Mobile App Development',
			href: '/services/mobile-app-development',
		},
		author: {
			name: 'Naveen Namdev',
			role: 'CTO',
			href: '/about/naveen-namdev',
			image: naveenNamdev,
		},
	},
];

const Blog = () => {
	return (
		<div className='bg-white py-24 sm:py-32'>
			<div className='mx-auto max-w-7xl px-6 lg:px-8'>
				<div className='mx-auto max-w-2xl lg:mx-0'>
					<h2 className='md:text-5xl text-3xl font-bold tracking-tight text-blue-900'>
						Blogs
					</h2>
					<p className='mt-2 text-lg leading-8 text-gray-700'>
						Explore our insightful blog, where we share expert perspectives,
						industry trends, and practical tips to navigate the ever-evolving IT
						landscape
					</p>
				</div>
				<div className='mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
					{posts.map((post) => (
						<article
							key={post.id}
							className='flex max-w-xl flex-col items-start justify-between'
						>
							<div className='bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max group'>
								<div className='flex items-center gap-x-4 text-xs'>
									<time dateTime={post.datetime} className='text-gray-500'>
										{post.date}
									</time>
									<a
										href={post.category.href}
										className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100'
									>
										{post.category.title}
									</a>
								</div>
								<div className='group relative'>
									<h3 className='mt-3 text-lg font-semibold leading-6 text-blue-900 group-hover:text-blue-800'>
										<a href={post.href}>{post.title}</a>
									</h3>
									<p className='mt-5 text-sm leading-6 text-gray-600'>
										{post.description}
									</p>
									<Link
										to={post.href}
										className='text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-4 py-2 my-4 text-sm shadow-xl rounded-2xl sm:w-auto sm:mb-0 group'
									>
										Read more
										<svg
											className='w-3 h-3 ml-1 group-hover: translate-x-2'
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 20 20'
											fill='currentColor'
										>
											<path
												fillRule='evenodd'
												d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
												clipRule='evenodd'
											></path>
										</svg>
									</Link>
								</div>
								<div className='relative mt-8 flex items-center gap-x-4'>
									<img
										alt=''
										src={post.author.image}
										className='h-10 w-10 rounded-full bg-gray-50'
									/>
									<div className='text-sm leading-6'>
										<p className='font-semibold text-gray-900'>
											<a href={post.author.href}>
												<span className='absolute inset-0' />
												{post.author.name}
											</a>
										</p>
										<p className='text-gray-600'>{post.author.role}</p>
									</div>
								</div>
							</div>
						</article>
					))}
				</div>
			</div>
		</div>
	);
};

export default Blog;

import React from "react";
import img from "../../images/scanlily.png";
import img2 from "../../images/nivoda.png";
import img3 from "../../images/cureasy.png";
import img4 from "../../images/teachquran.png";

const Portfolio = () => {
  return (
    <>
      <div className="my-4 py-4" id="portfolio">
        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
          Our Portfolio
        </h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-blue-900 mb-8"></div>
        </div>

        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max group">
              <div className="m-2 text-center text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img}
                />
                <h2 className="font-semibold my-4 text-2xl">
                  Scanlily LLC
                </h2>
                <label className="text-lg font-semibold">Organize, Track and Reserve</label>
                <p className="text-md font-medium">
                  Scanlily provides a powerful QR code-based method for managing your assets, equipment and inventory.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max group">
              <div className="m-2 text-center text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img2}
                />
                <h2 className="font-semibold my-4 text-2xl">
                  Nivoda
                </h2>
                <label className="text-lg font-semibold">The world's largest collection of natural diamonds</label>
                <p className="text-md font-medium">
                  The idea of Nivoda was inspired by the ongoing inefficiency of diamond trading.</p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max group">
              <div className="m-2 text-center text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img3}
                />
                <h2 className="font-semibold my-4 text-2xl">
                  Cureasy
                </h2>
                <label className="text-lg font-semibold">A great place of home health care</label>
                <p className="text-md font-medium">
                  To be the leading provider of accessible, innovative, and compassionate healthcare
                  solutions, empowering individuals and communities to live healthier, happier lives.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max group">
              <div className="m-2 text-center text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img4}
                />
                <h2 className="font-semibold my-4 text-2xl">
                  Teach Quran
                </h2>
                <label className="text-lg font-semibold">Best online quran academy</label>
                <p className="text-md font-medium">
                  Welcome to our online Quran academy, where we strive to provide a
                  high-quality, accessible, and personalized learning experience to 
                  students around the world.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;

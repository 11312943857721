import React from 'react';
import bhupendraSahu from '../../images/blogs/bhupendrasahu.jpeg';

const BlogITServices = () => {
	return (
		<div className='relative isolate overflow-hidden bg-white px-10 py-24 sm:py-34 lg:overflow-visible lg:px-12'>
			<div className='lg:col-span-2 lg:gap-x-8 lg:px-8'>
				<div className='relative mt-8 flex items-center gap-x-4'>
					<img
						alt=''
						src={bhupendraSahu}
						className='h-10 w-10 rounded-full bg-gray-50'
					/>
					<div className='text-sm leading-6'>
						<p className='font-semibold text-gray-900'>Bhupendra Sahu</p>
						<p className='text-gray-600'>Founder & CEO</p>
					</div>
				</div>
				<div className='flex items-center gap-x-4 text-xs'>
					<time dateTime='2024-04-27' className='text-gray-500'>
						27 Apr 2024
					</time>
					<p className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100'>
						IT Services
					</p>
				</div>
			</div>
			<div className='lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:gap-x-8 lg:px-8'>
				<h3 className='mt-3 text-lg font-semibold leading-6 text-blue-900 group-hover:text-blue-800'>
					Transforming Businesses with Comprehensive IT Services
				</h3>
				<p className='mt-5 text-sm leading-6 text-gray-600'>
					In today's rapidly evolving digital landscape, businesses must stay
					ahead of technological advancements to remain competitive. IT services
					play a pivotal role in driving innovation, enhancing efficiency, and
					ensuring security. At DelvonTech, we provide a wide range of IT
					services tailored to meet the unique needs of each client. In this
					blog, we'll explore the key IT services that can transform your
					business and set you on the path to success.
				</p>
				<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
					1. Managed IT Services
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					Managed IT services offer businesses the advantage of having a
					dedicated team to handle all IT-related tasks. This includes network
					management, data backup and recovery, software updates, and
					cybersecurity measures. By outsourcing these responsibilities,
					businesses can focus on their core operations while ensuring their IT
					infrastructure runs smoothly and securely.
				</p>
				<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
					2. Cloud Computing Solutions
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					Cloud computing has revolutionized the way businesses operate,
					offering scalable and cost-effective solutions for data storage,
					software deployment, and collaboration. Our cloud computing services
					enable businesses to access their data and applications from anywhere,
					at any time, while reducing the need for on-site hardware and
					maintenance.
				</p>
				<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
					3. Cybersecurity Services
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					In an age where cyber threats are increasingly sophisticated, robust
					cybersecurity measures are essential. Our cybersecurity services
					include threat detection and response, vulnerability assessments, and
					compliance management. We work proactively to protect your business
					from data breaches, malware, and other cyber threats, ensuring your
					sensitive information remains secure.
				</p>
				<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
					4. IT Consulting
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					Strategic IT consulting can help businesses align their technology
					initiatives with their overall goals. Our experienced consultants
					provide insights and recommendations on IT strategy, infrastructure
					optimization, and digital transformation. By leveraging our expertise,
					businesses can make informed decisions that drive growth and
					efficiency.
				</p>
				<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
					5. Software Development
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					Custom software development is crucial for businesses looking to
					create unique solutions that address specific needs. Our development
					team specializes in creating bespoke software applications, from
					enterprise resource planning (ERP) systems to customer relationship
					management (CRM) solutions. We ensure that the software we develop is
					scalable, user-friendly, and tailored to your business processes.
				</p>
				<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
					6. Network and Infrastructure Services
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					A reliable and efficient IT infrastructure is the backbone of any
					business. Our network and infrastructure services include network
					design and implementation, server management, and IT support. We
					ensure that your IT infrastructure is robust, scalable, and capable of
					supporting your business operations seamlessly.
				</p>
				<h2 className='mt-5 text-xl font-bold tracking-tight text-gray-700'>
					7. Data Analytics and Business Intelligence
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					Data is a valuable asset that can provide actionable insights when
					analyzed correctly. Our data analytics and business intelligence
					services help businesses harness the power of their data to make
					informed decisions. We provide data visualization, reporting, and
					predictive analytics to help you gain a competitive edge.
				</p>
				<h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-700'>
					Conclusion
				</h2>
				<p className='mt-2 text-sm leading-6 text-gray-600'>
					Incorporating these IT services into your business strategy can
					significantly enhance your operational efficiency, security, and
					innovation. At DelvonTech, we are committed to providing top-notch IT
					services that empower businesses to achieve their goals. Contact us
					today to learn how our tailored IT solutions can transform your
					business.
				</p>
			</div>
		</div>
	);
};

export default BlogITServices;

import React from "react";
import Cta from "../components/home/Cta";
import Hero from "../components/home/Hero";
import Intro from "../components/home/Intro";
import Portfolio from "../components/home/Portfolio";
import Services from "../components/home/Services";
import { Helmet } from "react-helmet";
import Testimonials from "../components/home/Testimonials";
import Clients from "../components/home/Clients";
import OurRatio from "../components/home/OurRatio";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Overview of DelvonTech</title>
        <meta
          name="description"
          content="We are a team of highly motivated and skilled developers dedicated to delivering only the best software."
        />
        <meta
          name="keywords"
          content="DelvonTech, Delvon-tech, technology solutions, tech services, delvontech"
        />
        <meta
          property="og:title"
          content="Bespoke software solutions for your unique business needs"
        />
        <meta
          property="og:description"
          content="We are a team of highly motivated and skilled developers dedicated to delivering only the best software."
        />
        <meta property="og:url" content="https://delvontech" />
        <meta
          property="og:image"
          content="http://delvontech/static/media/web-dev.fd61237124f5b922e412437caf61ea58.svg"
        />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Bespoke software solutions for your unique business needs"
        />
        <meta
          name="twitter:description"
          content="DelvonTech provides cutting-edge technology solutions and services. Discover our innovative products and expert team. Visit delvontech for more."
        />
        <meta
          name="twitter:image"
          content="http://delvontech/static/media/web-dev.fd61237124f5b922e412437caf61ea58.svg"
        />

        {/* Additional Meta Tags */}
        <meta name="author" content="DelvonTech" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://delvontech" />
      </Helmet>
      <Hero />
      <Intro />
      <Services />
      <OurRatio />
      <Portfolio />
      <Clients />
      <Testimonials />
      <Cta />
    </>
  );
};

export default Home;

import React from 'react';
import { useParams } from 'react-router-dom';
import BlogITServices from './BlogITServices';
import BlogWebDevelopment from './BlogWebDevelopment';
import BlogMobileAppDevelopment from './BlogMobileAppDevelopment';

const blogs = [
	{
		id: 1,
		blogId: 'it-services',
		component: <BlogITServices />,
	},
  {
		id: 2,
		blogId: 'web-development',
		component: <BlogWebDevelopment />,
	},
  {
		id: 3,
		blogId: 'mobile-app-development',
		component: <BlogMobileAppDevelopment />,
	},
];

const SubBlog = () => {
  const { id } = useParams();
  const findBlog = blogs.find((blog) => blog.blogId === id);
	return findBlog.component;
};

export default SubBlog;

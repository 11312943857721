import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Blog from './pages/Blog';
import PageNotFound from './components/PageNotFound';
import SubBlog from './components/blog/SubBlog';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animation
      once: true,     // Animation should happen only once
    });
  }, []);

  useDocTitle(" | Make a clear vision");

  return (
    <>
      <Router>
        <Header />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<SubBlog />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ScrollToTop>
        <Footer />
      </Router>
    </>
  );
}


export default App;
